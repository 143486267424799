

const projectViewContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    padding: 10,

}



const projectGridCardStyle = {
    placeSelf: 'center stretch',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    backgroundColor: "rgb(29, 29, 31)", 
    height: "100%", 
    overflow: 'hidden',
    padding: 30,
    boxShadow: '0px 30px 60px rgba(57, 57, 57, 0.1)',
    width: "100%",
}

const variants = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1, 
        transition: {
            delayChildren: .1,
            staggerChildren:  .1,
        }
    },
}

const items = {
    hidden: { opacity: 0, y: 500 },
    visible: {
        y: 0,
        opacity: 1
    }
}

const cardItems = (opacity) => {
  return { hidden: {
        opacity: opacity, y: 200 
    },
    visible: {
        opacity: opacity, 
        y: 0 ,
        transition: {
            type: "tween",
            duration: .5,
            delayChildren: .1,
            staggerChildren:  .2,
        }
    }
}
}

export {items, cardItems, variants, projectGridCardStyle,  projectViewContainerStyle}