

const variants = {
    hidden: { opacity: 0},
    visible: {
    opacity: 1,
    transition: {
        delayChildren: 1,
        staggerChildren:  1,
    }
    }
};

const item = {
    hidden: { scale: 0},
    visible: {
    scale: 1,
    transition: {
        delayChildren: .05,
        staggerChildren:  .1,
    }
    }
}

export {variants, item}