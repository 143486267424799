import React from "react";
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react";
import close from "../images/close.svg"
import menu from "../images/menu.svg"
import { Option, Menu, OptionType, MenuOption, ModalHandler } from "../types/types";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

export function BubbleMenu({setShowModal} : {setShowModal: () => void}) {

    const navigate = useNavigate()
    const {pathname}  = useLocation()
    const options : Option[] = [{title: "Experience", optionType: OptionType.Experience, link: "/experience", action: () => {}},
    {title: "Projects", optionType: OptionType.Projects, link: "/projects", action: () => {}},

]

    const [toggle, setToggle] = useState<boolean>(false);

    return <div style={{position: 'fixed', padding: '5px', zIndex: '10'}}>

        <motion.button 
        id="bubble-button"
         onClick={() => {setToggle(prev => !prev)}}
>
        <img src={menu}></img>
        </motion.button>
        <AnimatePresence>
{toggle && 
 <motion.div 
 id="bubble-menu-body"

 exit={{width: 40, height: 40, opacity: 0}}


 animate={{width: 350, height: 350}}
 transition={{type: "spring", stiffness: 90, damping:15}}

 >




{options.map(({optionType, title, link, action}) => (
    <button  style={{textDecoration: "none", background: "none", border: "none", }}
    onClick={() => navigate(link)} key={title}>
    <p style={{fontSize: 25, fontFamily: 'customFont2', color: "white"}} >{title}</p>
    </button>
    
))
}
<Link target= {"_blank" }
 style={{textDecoration: "none", background: "none", border: "none", }}
     rel={ "noopener noreferrer"}
     to="https://drive.google.com/file/d/1MS8icUF2AqhR0_al5lp5IZfGK37nZzjTdSAFZ-aborM/view">
<p style={{fontSize: 25, fontFamily: 'customFont2', color: "white"}} >Resume</p>
</Link>
<button  style={{textDecoration: "none", background: "none", border: "none", }} onClick={() => setShowModal()}>
<p style={{fontSize: 25, fontFamily: 'customFont2', color: "white"}} >Contact</p>
</button>

 </motion.div>
}

        
        </AnimatePresence>

    </div>
}


