import { AnimatePresence, motion } from "framer-motion"
import {variants, cardItems } from "../styles/ProjectViewDetailStyle"
import { Feature, Project, ProjectDetail} from "../types/types"
import { useScroll } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import more from '../images/more.svg'
import closecircle from '../images/closecircle.svg'
import {transition} from '../styles/Animation.js'

export function ProjectDetailView({features, imgWidth, name, type} : ProjectDetail & {imgWidth: number, name: string, type: string}) {
    return <motion.div 
    key={name}
    id="project-detail-container">
    <ProjectDetailGrid type={type} features={features} imgWidth={imgWidth}></ProjectDetailGrid>
    </motion.div>
}


function ProjectDetailGrid({features, imgWidth, type} : ProjectDetail & {imgWidth: number, type: string}) {


    return <motion.div
   
    key="grid-project"
    variants={variants}
    initial="hidden"
    animate="visible"
    id = "project-grid-container"
    >

    {features.map((item,index) => (
        <ProjectGridItemContent type={type}  key={index}  img={item.img} info={item.info} width={imgWidth} ></ProjectGridItemContent>
    ))
    }
    
   
    
    </motion.div>
}


function ProjectGridItemContent({img, info, width, type}: Feature & {width: number, type: string}) {

    const {scrollYProgress, scrollY} = useScroll()
    const gridItemRef = useRef<HTMLDivElement>(null)
    const [opacity, setOpacity] = useState(0)
    const [showVeil, setShowVeil] = useState(false)

    var newOpacity = () => {

        var opac = ((scrollY as any).current + window.innerHeight) as number / (gridItemRef.current!.offsetTop + gridItemRef.current!.scrollHeight) as number
        if (opac >= .8) {
            return 1
        } else {
            return opac * 1.2
        }
    }

    function manageOpacity() {
        setOpacity( gridItemRef.current ?  newOpacity() : 0 )
    }

    useEffect(() => {
        manageOpacity()
        document.addEventListener('scroll', () => {
        manageOpacity()
        }, true)
        return () => window.removeEventListener('scroll',manageOpacity)
    },[])


    return <motion.div 
    
    variants={cardItems(opacity)}
    ref = {gridItemRef}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false}}
    transition={{duration: .2}}
    className={" project-grid-item " + (type)}>
     
    <motion.img 
    initial={{ opacity: 0, y: 100}}
    whileInView={{ opacity: 1, y:  0 }}
    viewport={{ once: true }}
    transition={{duration: .5}}
    animate={{padding: showVeil ? 5 : 0}}
    style={{maxWidth: '100%'}}

    width={width}     
    src={img}
    
    >
    </motion.img>
   


<AnimatePresence>
{showVeil && (
        <motion.div 
        key = "veil"
        exit={{opacity : 0}}
        initial={{ opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: .2}}
        className="project-feature-veil">
            <p style={{color: 'white', fontFamily: 'customFont'}}>{info}</p>
        </motion.div>
)
}



    <motion.button 
    key = "veilbutton"
    transition={{duration: .5}}
    initial={{ opacity: 0, x: -50}}
    whileInView={{ opacity: 1, x:  0 }}
    viewport={{ once: true}}

    onClick={() => setShowVeil(prev => !prev)}
    className="view-button">

        { showVeil ? 
            <motion.img 
            key="close"
            initial={{ opacity: 0, scale: 0}}
            animate={{opacity: 1, scale: 1}}
            transition={transition}
            src ={closecircle}>
            </motion.img>
            : <motion.img 
            key="open"
            initial={{ opacity: 0, scale: 0}}
            animate={{opacity: 1, scale: 1}}
            transition={transition}
            src ={more}>     
            </motion.img>
        }
        
    </motion.button>

    </AnimatePresence>

    </motion.div>
}