import { motion } from "framer-motion"
import { contactSideBarModalStyle, contactViewContainerStyle, contactOptionCardStyle } from "../styles/ContactViewStyle"
import me from '../images/me.png'
import { transition } from "../styles/Animation";
import linkedinlogo from "../images/LI-In-Bug.png"
import githublogo from '../images/github-mark.png'
import mail from "../images/mail.svg"
import { useEffect, useRef } from "react";
import { ModalHandler } from "../types/types";
import { Link } from "react-router-dom";

export function ContactView({setShowModal, show} : ModalHandler & {show: boolean}) {

    const modalRef = useRef<HTMLDivElement>(null)


    return <motion.div 
    exit={{ opacity: 0}}
    onClick={(e) => { setShowModal(modalRef.current?.contains(e.target as HTMLDivElement))}}

    id = "contactview-container" >
        
         <ContactModal show={show} modalRef={modalRef}></ContactModal>

    </motion.div>
}

function ContactModal(props: {modalRef: React.Ref<HTMLDivElement>, show: boolean}) {



    return      <motion.div 
    key={String(props.show)}
    exit={{scale: 0, opacity: 0}}
    ref={props.modalRef}
    initial={{opacity: 0, scale: 0}}
    animate={{opacity: 1, scale: 1}}
    transition={transition}
    id = "contact-card" 
    >
    <BioCard></BioCard>
    <ContactOptionsCard></ContactOptionsCard>

</motion.div>


}

function BioCard() {
    return <motion.div style = {{display: 'flex'}}>
        <img   style={{width: 80, clipPath: 'circle(40%)'}}src={me}></img>
        <div style = {{flexDirection: 'column', 
        display: 'flex', justifyContent:'center', 

        alignItems: 'flex-start'}}>
            <h4 style = {{color: 'rgba(255,255,255,.33)'}}>Software Developer</h4>
            <h3 style = {{color: 'rgba(255,255,255,1)'}}>Arth Patel</h3>
        </div>
    </motion.div>
}

function ContactOptionsCard() {
    return <motion.div
    id="contact-option-card"
    
    >  
    <Link to ={"https://www.linkedin.com/in/patelrarth/"} target={"_blank"} rel={"oopener noreferrer"}>
    <img  width = {50} src = {linkedinlogo}></img>
    </Link>
    <Link to = {"https://github.com/patelarth0311"} target={"_blank"} rel={"oopener noreferrer"}>
    <img width = {50}  src = {githublogo}></img>
    </Link>
    <Link to = {"mailto:patelarth0311@gmail.com"} target={"_blank"} rel={"oopener noreferrer"}>
    <img width = {50}  src = {mail}></img>
    </Link>


    </motion.div>
}




