import { motion } from "framer-motion"
import { Experience } from "../types/types"

export function ExperienceView() {
    return <motion.div   id="experience-view-container"  >
    <ExperienceCard></ExperienceCard>
    </motion.div>
}

function ExperienceCard() {

    const experience: Experience = {location: "Atlanta, GA", period: "May 2022 - Aug 2022", role: "Software Engineer Intern", name: "Salesloft", 
    achievements: ["Developed with an agile backend team to prepare data lakes instrumental in supporting machine learning models for the company’s platform solutions serving over 4,000 clients, using Databricks, Python, Kafka, and Apache Spark",
"Implemented a Datadog dashboard that reports application status metrics, improving visibility by 100% on Apache Spark jobs’ performance and enabling proactive issue resolution",
"Improved querying performance by 20% on data lakes hosted on S3 by leveraging the Delta Lake framework",
"Orchestrated a continuous integration pipeline for Docker images used by Kubernetes clusters, streamlining QA testing",
"Collaborated with senior engineers to conduct peer views of code, ensuring overall code quality",
"Presented ongoing projects during product demonstrations to an audience of over 200 engineers and chief officials"]}

    return <motion.div>
    
    <ExperienceField  period = {experience.period} location={experience.location} role = {experience.role} name={experience.name} achievements={experience.achievements}></ExperienceField>
    </motion.div>
}

function ExperienceField({name, achievements, location, period, role}: Experience) {
    return <motion.div
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    transition={{duration: .5}}
    id = "experience-container"
    >
    <div 
    id = "job-info-container">
    <h4>{role}</h4>
    <h4>{name}</h4>
    <h5 style = {{color: "#8d8d8e"}}>{location}</h5>
    <h5 style = {{color: "#8d8d8e"}}>{period}</h5>
    </div>
    
    <ul id = "job-achievements-container" >
    {achievements.map((item,index) => (
        <li key = {index} style = {{textAlign: 'left', textIndent: 0}}>
            {item}
        </li>
    ))
    }
    </ul>
    </motion.div>
}
