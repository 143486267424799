import { TargetAndTransition, VariantLabels } from "framer-motion";

export type Experience = {
    name: string;
    achievements: string[];
    role: string;
    location: string;
    period: string;
}

export type Option = {
    title: string;
    optionType: OptionType
    link: string,
    action: () => void
}

export enum OptionType {
    Resume,
    Contact,
    Experience,
    Projects
}

export type MenuOption = {
    menuStyle: {}, 
    expand?: Boolean, 
}

export type Menu = {
    buttonStyle: React.CSSProperties;
    menuStyle: React.CSSProperties;
}

export type Project = {
    name: string
    features: Feature[]
    thumbprintWidth: number
    main: string
    imgWidth: number 
    type: string  
}

export type ProjectCard = {
    main : string
     thumbprintWidth : number
       refa: number | undefined
        onClick: (e? : number) => void
         id: number
         name: string
}

export type Feature = {
    img: string
    info: string
}

export type ProjectDetail = {
    features: Feature[]
}

export type ProjectGallery = {
    setShownProject: (e: Project) => void
     projects: Project[]
}

export type ModalHandler = {
    setShowModal: (val? : boolean) => void
}

