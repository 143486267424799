import { motion } from "framer-motion"
import { projectGalleryScrollViewStyle, items, viewButtonStyle,  galleryVariants } from "../styles/ProjectsViewStyle"

import bento from '../images/bento.png'
import bento2 from '../images/bento2.png'
import bento3  from '../images/bento3.png'
import bento4 from '../images/bento4.png'

import poke from "../images/poke.png"
import poke2 from "../images/poke2.png"
import poke3 from "../images/poke3.png"
import poke4 from "../images/poke4.png"

import nftsq from "../images/nftsq.png"
import compass from "../images/compass.png"
import compass2 from "../images/compass2.png"
import compass3 from "../images/compass3.png"
import { variants } from "../styles/ProjectsViewStyle"
import { Feature, Project, ProjectCard, ProjectGallery } from "../types/types";
import { ProjectDetailView } from "./ProjectDetailView"
import { useState, useRef, useEffect } from "react"
import data from '../data/projectdata.json'
import notify from "../images/notify.png"
import notify2 from "../images/notify2.png"
import notify3 from "../images/notify3.png"
import notify4 from "../images/notify4.png"
import notify5 from "../images/notify5.png"
import notify6 from "../images/notify6.png"
import musie0 from "../images/musie0.png"
import musie1 from "../images/musie1.png"
import musie2 from "../images/musie2.png"
import musie3 from "../images/musie3.png"
import musie4 from "../images/musie4.png"
import musie5 from "../images/musie5.png"
import musie6 from "../images/musie6.png"
import musie7 from "../images/musie7.png"
export function ProjectsView() {

    const projects: Project[] = 
    [{name: "Musie", 
    features: [{img: musie1, info: data.projects.musie.featureDescriptionOne},
       {img: musie2, info: data.projects.musie.featureDescriptionTwo},
        {img: musie5, info: data.projects.musie.featureDescriptionThree},
        {img: musie4, info: data.projects.musie.featureDescriptionFour},
        {img: musie3, info: data.projects.musie.featureDescriptionFive},
        {img: musie6, info: data.projects.musie.featureDescriptionSix},
        {img: musie7, info: data.projects.musie.featureDescriptionSeven}],
        
         thumbprintWidth: 150, main: musie0, imgWidth: 150, type: "musie"},

         {name: "Bento", 
    features: [{img: bento2, info: data.projects.eventreminder.featureDescriptionOne},
       {img: bento4, info: data.projects.eventreminder.featureDescriptionTwo},
        {img: bento, info: data.projects.eventreminder.featureDescriptionThree}],
         thumbprintWidth: 150, main: bento2, imgWidth: 150, type: "mobile"},
    {name: "myPokedex", 
    features: [{img: poke, info: data.projects.pokemon.featureDescriptionOne},
     {img: poke2, info: data.projects.pokemon.featureDescriptionTwo},
      {img: poke, info: data.projects.pokemon.featureDescriptionFive},
       {img: poke4, info: data.projects.pokemon.featureDescriptionFour},
       {img: poke3, info: data.projects.pokemon.featureDescriptionThree}],
        thumbprintWidth: 150, main: poke, imgWidth:150, type : "mobile"},
    {name: "Compass", 
    features: [{img: compass3, info: data.projects.compass.featureDescriptionOne},
    {img: compass2, info: data.projects.compass.featureDescriptionTwo},
    {img: compass, info: data.projects.compass.featureDescriptionThree}], 
    thumbprintWidth: 300, main: compass, imgWidth: 500, type: "web"},
    {name: "Notify", 
    features: [
      {img: notify4, info: data.projects.notify.featureDescriptionOne},
      {img: notify6, info: data.projects.notify.featureDescriptionTwo},
      {img: notify, info: data.projects.notify.featureDescriptionThree},
    {img: notify2, info: data.projects.notify.featureDescriptionFour},
    {img: notify3, info: data.projects.notify.featureDescriptionFive}], 
    thumbprintWidth: 300, main: notify, imgWidth: 500,type: "web"}
  
  
  ]


    const[shownProject, setShownProject] = useState(projects[0])

    const [ready, setReady] = useState(document.readyState === 'complete')
    
    useEffect(() => {
      const onPageLoad = () => {
        setReady(true)
      };
  
      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        console.log("a")
        window.addEventListener('load', onPageLoad);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
    }, []);
    
    return <motion.div 
    variants={variants} 
    initial="hidden"
    id="project-view-container"
    animate="visible">
    {ready && (
<>
<ProjectGalleryScrollView projects={projects} setShownProject  = {(val: Project) => setShownProject(val)}></ProjectGalleryScrollView>
<ProjectDetailView type={shownProject.type} name = {shownProject.name} features={shownProject.features} imgWidth={shownProject.imgWidth} ></ProjectDetailView>
</>
    )

    }




    </motion.div>
}

function ProjectGalleryScrollView({setShownProject, projects}: ProjectGallery) {

  const [currentRef, setRef] = useState<number | undefined>(0)

  return <div 
  id="gallery-container"
  >
  <motion.div 
  variants={galleryVariants}
  initial="hidden"
  animate="visible"
  id = "project-gall-container" 
  >

    {projects.map((item, index) => (
          <ProjectCardView  id={index} key={index} name={item.name} main={item.main} refa={currentRef} onClick={function (e): void {
        setRef(index)
        setShownProject(item)
      } }  thumbprintWidth={item.thumbprintWidth}></ProjectCardView>
    ))
    }
  </motion.div>
  </div>
  
}
function ProjectCardView( {main, thumbprintWidth,  refa, onClick, id, name} : ProjectCard) {
  return <motion.div
  style={{position: 'relative'}}
  >
    <motion.div  
    className="project-card"
    transition={{duration: .6}}
    variants={items}>
    <img width= { thumbprintWidth } src={main}></img>
    <motion.button 
    onTap={() => {
      onClick(id)
    }}
    style = {{...viewButtonStyle, background: refa === id ? '#FA4A0C' : 'none', color: refa === id ? 'white' : 'black'}}>

    <p style={{fontSize: 15, fontFamily: 'customFont2'}}>{name}</p>

    </motion.button>
    </motion.div>
  </motion.div>
}