import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Landing } from './components/Landing';
import { Routes, Route } from "react-router-dom"
import { ExperienceView } from './components/ExperienceView';
import { ProjectsView } from './components/ProjectsView';
import { MenuSideBar } from './components/MenuSideBar';
import { ContactView } from './components/ContactView';
import { useEffect, useState } from 'react';
import { AnimatePresence } from "framer-motion";
import { motion } from 'framer-motion';
import { BubbleMenu } from './components/BubbleMenu';

function App() {



    const [showModal, setShowModal] = useState(false)

    const [test, setTest] = useState(false)


  return (
    <div className="App">
<BubbleMenu setShowModal={() => {
  setShowModal(prev => !prev) 

}}></BubbleMenu>
<AnimatePresence>
{showModal && (
  <ContactView show={showModal}  setShowModal={
    (val?:boolean) => {
      setShowModal(val!)
      if (!val) {
        document.body.style.overflow = 'scroll'
      }
    
    }
   
   } ></ContactView>
)
}
</AnimatePresence>



<Routes>
<Route path="/" element={<Landing />}></Route>
<Route path="/experience" element={<ExperienceView/>}></Route>
<Route path="/projects" element={<ProjectsView/>}></Route>
</Routes>
</div>
  
    

  );
}
export default App;
/*

<MenuSideBar setShowModal={() => {
  setShowModal((prev) => !prev)
  document.body.style.overflow = showModal ? 'scroll' : 'hidden'

 } } />
*/