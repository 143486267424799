const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}


const projectViewStyle = {
    ...containerStyle,
    flexDirection: 'column',
    backgroundColor: "white",


}

const projectGalleryScrollViewStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-end',
    columnGap: 50,
    padding: 30
}

const galleryContainerStyle = {
    display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'
}

const variants = {
    hidden: { opacity: 1},
    visible: {
    opacity: 1,
    transition: {
        delayChildren: .8,
        staggerChildren:  .5,
    }
    }
};

const galleryVariants = {
    hidden: { opacity: 1},
    visible: {
    opacity: 1,
    transition: {
        delayChildren: .1,
        staggerChildren:  .1,
    }
    }
}


const items = {
    hidden: {opacity: 0, x: -500},
    visible: {
        x: 0,
        opacity: 1
    }
}

const projectCardStyle = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    minWidth: 120

}

const viewButtonStyle = {
    background: 'none',
    borderRadius: 30,
    border: 'none',
    width: 159,
    height: 60,
}

export {galleryVariants, galleryContainerStyle, viewButtonStyle, projectCardStyle, variants, items, containerStyle, projectViewStyle, projectGalleryScrollViewStyle }