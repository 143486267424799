import { motion } from "framer-motion"
import me from '../images/me.png'
import {  variants, item } from "../styles/LandingStyle"
import bubble from '../images/bubble.svg'
import { transition } from "../styles/Animation";
import { relative } from "path";
import { Link } from "react-router-dom";
import linkedinlogo from "../images/LI-In-Bug.png"
import githublogo from '../images/github-mark.png'
import mail from "../images/mail.svg"
export function Landing() {

    return <motion.div

    initial="hidden"
    animate="visible"
    variants = {item}
    id="landing-view-container">
    <motion.div
    id="bio-container"
    >

<motion.div     variants = {item}    transition={transition}    className="bento-item">
<motion.img 
 
    src={me} style={{height: 130, clipPath: 'circle(40%)'}}>
    </motion.img>

       </motion.div>
       <motion.div variants = {item}    transition={transition}    className="bento-item">
   
   <div>
   <h3 style={{color: 'white'}}>Arth Patel</h3>
        <p style = {{color: "#8d8d8e"}}>Developer</p>
   </div>
     

 
      
       <p style = {{color: "#8d8d8e"}}>Georgia</p>
   
      
       </motion.div>


       <motion.div className="bento-item" variants = {item}    transition={transition} >
        <p style={{color: 'white'}}>Let's connect</p>
        <div>
        <Link to ={"https://www.linkedin.com/in/patelrarth/"} target={"_blank"} rel={"oopener noreferrer"}>
    <img  width = {35} src = {linkedinlogo}></img>
    </Link>
    <Link to = {"https://github.com/patelarth0311"} target={"_blank"} rel={"oopener noreferrer"}>
    <img width = {35}  src = {githublogo}></img>
    </Link>
        </div>
       </motion.div>
      
       <motion.div variants = {item}    transition={transition}  className="bento-item">
      <p style = {{color: "#8d8d8e"}}>
Hello, I'm Arth, a developer who is driven by curiosity and a passion to help through technology</p>

       </motion.div>
       
      </motion.div >
      








    </motion.div>
}